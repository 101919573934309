<template>
  <div :class="'home ' + (noticeShow ? 'glo-magbot' : 'glo-magbot0')">
    <div class="weather">天气实况</div>
    <!-- <div class="container">
      <van-row class="weather-info">
            <van-col span="12">
              <img :src="require('@/assets/weather/'+weather.icon+'.png')" class="weather-icon">
            </van-col>
            <van-col span="12" class="temp">
              <div>{{weather.temp || 0}}°</div>
              <div class="label">{{weather.text || '多云'}}</div>
            </van-col>
      </van-row>
      <van-row class="weather-tool">
          <van-col span="8">
              <div><i class="iconfont icon-iconset0469"></i></div>
              <div class="text">{{weather.windSpeed || 0}} km/h</div>
          </van-col>
          <van-col span="8">
              <div><i class="iconfont icon-shidu"></i></div>
              <div class="text">{{weather.humidity || 0}}%</div>
          </van-col>
          <van-col span="8">
              <div><i class="iconfont icon-jiangyuliang"></i></div>
              <div class="text">{{weather.precip || 0}} mm</div>
          </van-col>
      </van-row>
    </div> -->
    <div class="z-weather">
      <van-row class="z-top">
          <van-col span="12" class="border-right bg1">
            <div class="z-info">{{weather.envtemp || 0}}℃</div>
            <div><i class="iconfont icon-wendu"></i></div>
            <div class="z-txt">温度</div>
          </van-col>
          <van-col span="12" class="border-right bg2">
            <div class="z-info">{{weather.moisture || 0}}%</div>
            <div><i class="iconfont icon-shidu"></i></div>
            <div class="z-txt">湿度</div>
          </van-col>
      </van-row>
      <van-row class="z-bottom">
          <van-col span="8" class="border-right">
            <div class="z-info">{{weather.windspeed || 0}}m/s</div>
            <div><i class="iconfont icon-fengsu"></i></div>
            <div class="z-txt">环境风速</div>
          </van-col>
          <van-col span="8" class="border-right">
            <div class="z-info">{{weather.windforce || 0}} 级</div>
            <div><i class="iconfont icon-iconset0469"></i></div>
            <div class="z-txt">环境风力</div>
          </van-col>
          <van-col span="8" >
            <div class="z-info">{{weather.winddir || '北风'}}</div>
            <div><i class="iconfont icon-fengxiang"></i></div>
            <div class="z-txt">环境风向</div>
          </van-col>
      </van-row>
    </div>
    <div class="relative chart-content">
      <van-circle v-model="currentRate" layer-color="#e2e2e2" :rate="healthVal || 55" :speed="100" :color="gradientColor" :stroke-width="65" size="220px">
            <div class="glo-inner-align">
        <transition name="circle-text">
              <div v-if="!time" key="text1">
                  <div class="time">{{nowTime}}</div>
                  <div class="tag">北京时间</div>
              </div>
              <div v-else key="text2">
                  <div class="time">{{healthVal || 0}}</div>
                  <div class="tag">综合评估</div>
              </div>
        </transition>
            </div>
      </van-circle>
    </div>
    <div class="show-status">
      <van-row>
        <van-col span="12" class="border-right right"><i :class="{'fa fa-sun-o':true,'fa-active':time}" @click="time = true"></i></van-col>
        <van-col span="12" class="left"><i :class="{'fa fa-moon-o':true,'fa-active':!time}" @click="time = false"></i></van-col>
      </van-row>
    </div>    
    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/slope/tabbar2.vue'

export default {
  name: 'Home',
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        time:true,
        nowTime:'00:00',
        currentRate:0,
        gradientColor: {
          '0%': '#3fecff',
          '100%': '#6149f6',
        },
        bridgeCode:'',
        healthVal:'',
        magbotVal:'',
        weather:{
          // icon:'104',
          envtemp:'0',
          moisture:'0',
          windspeed:'0',
          windforce:'0',
          windangle:'0',
          winddir:'...'
        },
        downTime:null
    }
  },
  // 接收父组件的值
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 获取当前时间
    getNowTime(){
      let time = new Date();
      return `${time.getHours()}:${time.getMinutes()<10 ? '0'+time.getMinutes() : time.getMinutes()}`
    },
    // 获取桥梁健康值
    healthInfo(){
      this.$api.SLOPE.getHealth({
        bridgeCode:this.bridgeCode
      }).then( d=>{
        this.healthVal = d.briHealth *10;
      })
    },
    // 获取天气预报信息
    Weather(){
      // this.$api.SLOPE.getWeather(this.bridgeCode).then( d => {
      //     this.weather = {...d.now};
      // })
      this.$api.SLOPE.getEnv({
        bridgeCode:'510321003'
      }).then( d=> {
          this.weather.envtemp = d.envTemp;
          this.weather.moisture = d.moisture;
          this.weather.windspeed = d.windspeed;
          this.weather.windforce = d.windforce;
          this.weather.windangle = d.windangle;
          this.weather.winddir = this.format(d.winddir);
          this.loading = false;
      })
    },
    // 格式化风向
    format(d){
      let text = '';
        d == '0' ? text='北风' :
        d == '1' ? text='东北风' :
        d == '2' ? text='东风' :
        d == '3' ? text='东南风' :
        d == '4' ? text='南风' :
        d == '5' ? text='西南风' :
        d == '6' ? text='西风': text='西北风' ;
      return text;
    }
  },
  mounted(){
    this.bridgeCode = window.sessionStorage.getItem('bridgeCode');
    if(this.bridgeCode){
      this.healthInfo();
      this.Weather();
    }
    // 更新当前时间
    this.downTime = setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
  beforeDestroy(){
    this.downTime ? clearInterval(this.downTime) : void 0;
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-content{
    padding-top: 8%;
    .time{
      font-size: 3.2rem;
      color: rgb(92, 75, 66);
      margin-bottom: 4%;
      font-weight: bold;
    }
    .tag{color:#a5a5a5;letter-spacing: 2px;font-size: .85rem;}
}
.flex{display: flex;justify-content: left;align-self: center;padding-left: 25%;}
.show-status{
  margin: 4%;
  font-size: 2.5rem;color:#a5a5a5;
  .right{text-align: right;padding-right: 5%;}
  .left{text-align: left;padding-left: 5%;}
  .border-right{border-right: 2px solid rgb(231, 231, 231);}
  .fa-active{color: #2a90e2;}
}
.circle-text{
  position: fixed;left:0;right: 0;
  width: 100%;
}
.circle-text-enter, .circle-text-leave-to
{
  left:0;top: 0;right: 0;
  position: absolute;
  transform:translateX(0px) translateY(0px) scale(0.2);
  opacity:1;
}
.circle-text-enter-active {
  transition: all 0.6s ease;
}
.circle-text-leave-active {
  transition: all .1s ease;
  transform:translateX(0) translateY(0) scale(0) ;
  opacity: 0;
  z-index: 100;
}
// 天气样式
.weather{
    height: 4rem;
    background: linear-gradient(#2a90e2 0%, #66b8fb 85%);
    color: #fff;
    line-height: 4;
    font-size: .95rem;
}
.container{
  background: #d7f3fb;padding: 10px;border-bottom-right-radius: 50px;border-bottom-left-radius: 50px;
  .weather-info{
    .weather-icon{width: 85px;height: 85px;}
    .iconfont{font-size: 5rem;color: #2faddf;line-height: 1;}
    .temp{
      font-size: 4rem;line-height: 1;font-weight:bold;
      .label{font-size: .85rem;margin-left: -5%;}
    }
  }
  .weather-tool{
    margin: 6% 0 4% 0;
    .iconfont{font-size: 2rem;color: #2faddf;line-height: 1;}
    .text{font-size: .85rem;margin-top: 4%;color: #737373;}
    .icon-iconset0469{color: #650c0c;}
    .icon-shidu{color: #d46d6d;}
    .icon-jiangyuliang{color: #e06d18;}
  }
}

.z-weather{
  padding: 4%;
  .van-col{
    padding: 4%;
    background-color: #f1f1f1;
    .z-info{
      color: #2a90e2;
      line-height: 1.5;
      font-size: 1.5rem;
    }
    .iconfont{
      font-size: 1.4rem;
      color: #e06d18;
      margin: 4% 0;
      display: inline-block;
    }
    .z-txt{
      color:#878787;
      font-size: .85rem;
    }
  }
  .z-top{
    margin: 1% 0;
    .z-info{
      color: #fff;
    }
    .z-txt{
      color:#505050;
    }
  }
  .z-bottom{
    .z-info{
      font-size: 1.2rem;
    }
  }
  .border-right{
    border-right: 2px solid #fff;
  }
  .bg1{
      background-color: #77cdff;
  }
  .bg2{
      background-color: #72ddd8;
  }
}
</style>